<template>
  <div class="home">
    <header>
      <img alt="Vue logo" src="../assets/nylogo.svg" />
      <HelloWorld msg="LT Wishes" />
    </header>
    <main>
      <div class="container">
        <div class="wishes" id="wishesData" @click="justFunMethod">
          <div class="column-of-wishes" id="columnOfWishes1"></div>
          <div class="column-of-wishes" id="columnOfWishes2"></div>
          <div class="column-of-wishes" id="columnOfWishes3"></div>
          <div class="column-of-wishes" id="columnOfWishes4"></div>
        </div>
      </div>
    </main>
    <footer>
      <p>
        Made with <span id="heartPulse" @click="heartPulse">❤️</span> by
        <a href="https://lullis.ru" target="_blank" rel="noopener">lullis</a>
      </p>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      justFunCounter: 1,
    };
  },
  methods: {
    justFunMethod() {
      console.log(
        "Кажется ты что-то отыскал, попробуй сделать ещё пару кликов!\nСейчас кликов: " +
          this.justFunCounter
      );
      if (this.justFunCounter < 7) {
        this.justFunCounter += 1;
      } else {
        console.log("Пасхалка найдена! :)");
        let allHints1 = document.getElementById("columnOfWishes1").children;
        let allHints2 = document.getElementById("columnOfWishes2").children;
        let allHints3 = document.getElementById("columnOfWishes3").children;
        let allHints4 = document.getElementById("columnOfWishes4").children;
        for (let i = 0; i < allHints1.length; i++) {
          allHints1[i].classList.add("justFunEasterEgg");
        }
        for (let i = 0; i < allHints2.length; i++) {
          allHints2[i].classList.add("justFunEasterEgg");
        }
        for (let i = 0; i < allHints3.length; i++) {
          allHints3[i].classList.add("justFunEasterEgg");
        }
        for (let i = 0; i < allHints4.length; i++) {
          allHints4[i].classList.add("justFunEasterEgg");
        }
      }
    },
    heartPulse() {
      let hp = document.getElementById("heartPulse");
      hp.classList.toggle("pulse");
    },
  },
  mounted() {
    // пасхалка конами-код
    var secret_combination = "0";
    document.addEventListener("keyup", function (e) {
      var kcode = e.keyCode;
      secret_combination = secret_combination += kcode;
      if (secret_combination == "038384040373937396665") {
        alert(
          "Плюс 0.5 балла на контрольной!\n\n≧◠ᴥ◠≦\n\nПодойди перед контрольной и скажи код:\n 'Кот Наоборот!'\n Я всё пойму без объяснений :)"
        );
      }
    });
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@400;700&display=swap");

* {
  font-family: "Jura", sans-serif;
  font-size: 1em;
}

.home {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

// svg {
//   filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
//   position: absolute;
//   top: -30%;
//   left: -14%;
//   width: 60%;
// }

header {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

img {
  width: 100%;
  max-width: 512px;
  height: auto;
  filter: drop-shadow(0px 0px 10px rgba(32, 97, 68, 0.8));
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0 25px 15px 0px rgba(0, 0, 0, 0.2));
    transform: translatey(-18px);
  }
  100% {
    filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 0.6));
    transform: translatey(0px);
  }
}

main {
  margin-top: 1em;
}

.container {
  width: 1200px;
  padding: 16px;
  margin: auto;
}

.wishes {
  // display: grid;
  // grid-gap: 16px;
  // grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  // grid-auto-flow: dense;
  column-count: 4;
  column-gap: 1rem;
  width: 100%;
}

.column-of-wishes {
  break-inside: avoid;
  margin-bottom: 1rem;
}

.wish {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 1em;
  color: #222;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.wish:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.justFunEasterEgg {
  animation: justFun 3s linear infinite alternate;
}

@keyframes justFun {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(360deg) scale(1.5);
  }
}

.wish .content {
  word-wrap: break-word;
  padding: 1em;
  text-align: start;
}

.wish .content .header {
  font-weight: 700;
}

.wish .content .meta {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 5px;
  padding-top: 3px;
  color: rgba(34, 34, 34, 0.8);
}

.wish .content .description {
  padding-top: 1em;
}

.wish .extra {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2px;
}

.mini-image {
  width: 40px;
  height: 40px;
  background-size: cover;
}

.image-random0 {
  background-image: url("../assets/bauble1.png");
}

.image-random1 {
  background-image: url("../assets/bauble2.png");
}

.image-random2 {
  background-image: url("../assets/bauble3.png");
}

.image-random3 {
  background-image: url("../assets/bauble4.png");
}

@media screen and (max-width: 1024px) {
  .container {
    width: 1024px;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 768px;
    margin: auto;
  }
  .wishes {
    column-count: 2;
  }
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
    margin: auto;
  }
  .wishes {
    column-count: 1;
  }
}

footer p {
  padding: 2em;
}

footer p a {
  color: #fff;
}

// анимация для сердечка
.pulse {
  animation: pound 0.85s infinite alternate;
  -webkit-animation: pound 0.85s infinite alternate;
}
@keyframes pound {
  0% {
    font-size: 1.2em;
  }
  25% {
    font-size: 0.8em;
  }
  50% {
    font-size: 1.2em;
  }
  70% {
    font-size: 1em;
  }
  90% {
    font-size: 1em;
  }
  100% {
    font-size: 1em;
  }
}
</style>
