import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import { rtdbPlugin } from "vuefire";
Vue.use(rtdbPlugin);

import "./snow.js";
import "./firebase.js";

Vue.config.productionTip = false;

new Vue({
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
