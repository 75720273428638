<template>
  <div class="hello">
    <h1 @click="$refs.newYear.toggleCd()">{{ msg }}</h1>
    <countdown ref="newYear"></countdown>
  </div>
</template>

<script>
import Countdown from "./Countdown";
export default {
  name: "HelloWorld",
  components: {
    Countdown,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  font-size: 6vw;
  position: relative;
  z-index: 5;
  color: #fff;
  filter: drop-shadow(0px 0px 10px rgba(32, 97, 68, 0.8));
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 15vw;
  }
}
</style>
