/* eslint-disable */
import firebase from "firebase/app";
import "firebase/database";

export const db = firebase
  .initializeApp({ databaseURL: "https://wish-hoho.firebaseio.com" })
  .database();

export let wishesCount = 1

/* Start read from DB */
db.ref("users").once("value", (snapshot) => {
  const usersData = snapshot.val();
  wishesCount = usersData.length;
  // console.log(wishesCount);
  document.getElementById('wish-count').innerHTML = wishesCount;
  // получили данные и посчитали число пожеланий, а теперь формируем карточку желания
  for (let i = 0; i < usersData.length; i++) {
    if (usersData[i].userwho == 0) {
      usersData[i].userwho = "Ученик"
    } else if (usersData[i].userwho == 1) {
      usersData[i].userwho = "Ученик"
    } else {
      usersData[i].userwho = "Друг"
    }
    const imageRandom = Math.floor(Math.random() * 4);
    let oneWish = "<div class='wish' id='wishHint'><div class='content'><div class='header'>" + usersData[i].username + "</div><div class='meta'><span class='category'>" + usersData[i].userwho + "</span></div><div class='description'><p>" + usersData[i].userwish + "</p></div></div><div class='extra content'><span>" + usersData[i].datetoday + "</span><div class='around-image'><div class='mini-image image-random" + imageRandom + "'></div></div></div></div>"
    if (i % 4 == 0) {
      document.getElementById('columnOfWishes4').innerHTML += oneWish;
    }
    if (i % 4 == 1) {
      document.getElementById('columnOfWishes3').innerHTML += oneWish;
    }
    if (i % 4 == 2) {
      document.getElementById('columnOfWishes2').innerHTML += oneWish;
    }
    if (i % 4 == 3) {
      document.getElementById('columnOfWishes1').innerHTML += oneWish;
    }
    // let wishes = document.getElementById('wishesData');
    // wishes.innerHTML += oneWish;
  }
});
/* End read from DB */

