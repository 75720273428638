<template>
  <transition name="fade">
    <div class="time timetoggle">
      <span>
        <div id="d">00</div>
        Days
      </span>
      <span>
        <div id="h">00</div>
        Hours
      </span>
      <span>
        <div id="m">00</div>
        Minutes
      </span>
      <span>
        <div id="s">00</div>
        Seconds
      </span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Countdown",
  data() {
    return {
      showcd: false,
    };
  },
  methods: {
    toggleCd() {
      this.showcd = true;
      document.querySelector(".time").classList.toggle("timetoggle");
    },
  },
  mounted() {
    // Simple CountDown Clock

    const d = document.getElementById("d");
    const h = document.getElementById("h");
    const m = document.getElementById("m");
    const s = document.getElementById("s");

    function getTrueNumber(num) {
      return num < 10 ? "0" + num : num;
    }

    function calculateRemainingTime() {
      const comingYear = new Date().getFullYear() + 1;
      const comingDate = new Date(`Jan 1, ${comingYear} 00:00:00`);

      const now = new Date();
      const remainingTime = comingDate.getTime() - now.getTime();
      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const mins = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const secs = Math.floor((remainingTime % (1000 * 60)) / 1000);

      d.innerHTML = getTrueNumber(days);
      h.innerHTML = getTrueNumber(hours);
      m.innerHTML = getTrueNumber(mins);
      s.innerHTML = getTrueNumber(secs);

      return remainingTime;
    }

    function initCountdown() {
      const interval = setInterval(() => {
        const remainingTimeInMs = calculateRemainingTime();

        if (remainingTimeInMs <= 1000) {
          clearInterval(interval);
          initCountdown();
        }
      }, 1000);
    }

    initCountdown();
  },
};
</script>

<style lang="scss" scoped>
.time {
  color: #eb5f5f;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 6px;
  max-width: fit-content;
  margin: 2em auto 0;
  // text-shadow: 3px 3px rgb(32 97 68 / 30%);
  filter: drop-shadow(0px 0px 10px rgba(32, 97, 68, 0.8));
}

.timetoggle {
  display: none;
}

.time span {
  padding: 0 14px;
  font-size: 0.8rem;
}

.time span div {
  font-size: 3rem;
}

@media (max-width: 768px) {
  .label {
    font-size: 1.7rem;
  }
  .time span {
    padding: 0 16px;
    font-size: 0.6rem;
  }
  .time span div {
    font-size: 2rem;
  }
}
</style>
