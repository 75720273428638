<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view />
    <canvas id="sky"></canvas>
    <div
      class="santa"
      @click="$refs.modalWish.openModal(), (isHidden = !isHidden)"
      v-if="isHidden"
    >
      <div class="circles"></div>
      <div class="snow"></div>
      <div class="hat">
        <div class="hat-end"></div>
      </div>
      <div class="face">
        <div class="eyes"></div>
        <div class="mouth"></div>
      </div>
      <div class="dirty-overflow">
        <div class="body"></div>
      </div>
    </div>
    <div class="grinch">
      <div class="find-me-grinch"></div>
      <span id="wish-count">5</span><span>wishes</span>
    </div>
    <modal ref="modalWish">
      <template v-slot:header>
        <h2>Твоё пожелание</h2>
      </template>

      <template v-slot:body>
        <form id="wish-sender" @submit.prevent="greet">
          <input
            type="text"
            v-model="username"
            placeholder="Твои имя и фамилия"
            value=""
            id="username"
            required
          />
          <select id="user-who" v-model="userwho" required>
            <option disabled value="">Кто ты? :)</option>
            <option value="0">Ученик (2-5 классы)</option>
            <option value="1">Ученик (6-11 классы)</option>
            <option value="2">Друг</option>
          </select>
          <textarea
            v-model="userwish"
            id="user-wish"
            cols="30"
            rows="5"
            placeholder="Твоё пожелание"
            required
          ></textarea>
          <p>
            <input
              class="send-wish"
              id="send-wish"
              type="submit"
              value="Пожелать"
            />
          </p>
          <p class="warning" v-if="warningShow" v-html="warningText"></p>
        </form>
      </template>

      <template v-slot:footer>
        <div>
          <p>made by lullis</p>
        </div>
      </template>
    </modal>
    <modal ref="modalGift">
      <template v-slot:header>
        <div class="gift-img"></div>
      </template>

      <template v-slot:body>
        <h2>Пожелание ^_^</h2>
        <!-- <p class="gift-text">Поздравляю тебя с Новым годом и желаю ...</p> -->
        <video src="./assets/mywish.mp4" autoplay controls></video>
        <a href="" class="send-wish">Спасибо ^_^</a>
      </template>

      <template v-slot:footer> </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./components/Modal";
import { db } from "./firebase.js";
import { wishesCount } from "./firebase.js";

export default {
  name: "App",
  components: {
    Modal,
  },
  data() {
    return {
      username: "",
      userwho: "",
      userwish: "",
      allUsers: [],
      isHidden: false,
      warningShow: false,
      warningText: "",
      errors: [],
    };
  },
  firebase: {
    allUsers: db.ref("users"),
  },
  methods: {
    // пишем метод отправки пожелания по кнопке
    greet() {
      // обработка ошибок формы
      let self = this;
      let users = db.ref("/users/" + wishesCount);
      // не забываем про число текущих пожеланий!
      let dateIsToday = new Date();
      let dateIsTodayString = dateIsToday.toLocaleDateString("ru-RU");
      // узнаем сегодняшнюю дату
      let data_wish = {
        username: this.username,
        userwho: this.userwho,
        userwish: this.userwish,
        datetoday: dateIsTodayString,
      };
      users.set(data_wish, finished);
      // массив инфы 1-го пожелания + отправка с помощью set()

      function finished(error) {
        if (error) {
          console.log("ooops");
          self.warningShow = true;
          self.warningText =
            "Сервис получает много пожеланий, попробуйте через минутку :)";
        } else {
          console.log("data saved!");
          self.$refs.modalWish.closeModal();
          self.$refs.modalGift.openModal();
        }
      }
    },
  },
  mounted() {
    let self = this;
    // доступ строго в декабре
    let date_winter = new Date();
    let december = date_winter.getMonth();
    if (december == 11) self.isHidden = true;
    // резервный доступ к санте, чтобы отправить пожелание
    let secret_combination_santa = "0";
    document.addEventListener("keyup", function (e) {
      let kcode_s = e.keyCode;
      secret_combination_santa = secret_combination_santa += kcode_s;
      if (secret_combination_santa == "08365788465") {
        self.isHidden = true;
      }
    });
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-thumb {
  background: #d41515;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #0b6648;
}
::-webkit-scrollbar-track {
  background: #0f8a5f;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: url(assets/christmas.png), #0f8a5f;
  background-blend-mode: soft-light;
  background-size: 20%;
  background-attachment: fixed;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
}

.overflow-hidden {
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

// Santa
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: url("assets/cursor.png"), auto;
}

.santa {
  background: #0f8a5f;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 8;
  animation: float 4s ease-in-out infinite;
  // filter: drop-shadow(0px 0px 10px rgba(32, 97, 68, 0.8));
}

.santa .snow {
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: 18px;
  top: 44px;
}

.santa .snow:after {
  width: 6px;
  height: 6px;
  content: "";
  display: inline-block;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -14px;
  left: 0px;
}

.santa .snow:before {
  width: 4px;
  height: 4px;
  content: "";
  display: inline-block;
  background: white;
  border-radius: 50%;
  position: absolute;
  bottom: -12px;
  left: -11px;
}

.santa .hat {
  width: 64px;
  height: 31px;
  border-radius: 200px 200px 0 0;
  background: #ed1c24;
  margin: 0 auto;
  top: -11px;
  position: relative;
  left: 5px;
  z-index: 20;
}

.santa .hat:after {
  width: 60px;
  height: 15px;
  border-radius: 20px;
  content: "";
  display: block;
  background: white;
  position: relative;
  top: 22px;
  left: -3px;
}

.santa .face {
  width: 54px;
  height: 57px;
  background: white;
  border-radius: 0px 0px 50px 50px;
  margin: 0 auto;
  position: relative;
  z-index: 20;
  top: -6px;
}

.santa .face .eyes {
  background: #f1ddd5;
  width: 45px;
  height: 15px;
  margin: 0 auto;
  position: relative;
  left: -1px;
  text-align: center;
  top: 4px;
  padding-top: 0px;
}

.santa .face .eyes:after,
righteye {
  width: 8px;
  height: 6px;
  background: transparent;
  box-shadow: 0px -2px #793623;
  content: "";
  display: inline-block;
  margin: 0 auto;
  border-radius: 50%;
}

.santa .face .eyes:before,
lefteye {
  width: 8px;
  height: 6px;
  background: transparent;
  box-shadow: 0px -2px #793623;
  content: "";
  display: inline-block;
  margin: 0 auto;
  border-radius: 50%;
  margin-right: 5px;
}

.santa .mouth {
  background: #a31f2b;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: 20px;
  right: 17px;
}

.santa .body {
  width: 93px;
  height: 84px;
  background: #ed1c24;
  border-radius: 90px 90px 0px 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
}

.santa .body:after {
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 68px;
  z-index: 50;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
}

.santa .body:before {
  width: 5px;
  height: 5px;
  background: white;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 55px;
  z-index: 50;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
}

.santa .hat .hat-end {
  width: 13px;
  height: 32px;
  content: "";
  background: #ed1c24;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 15px;
  border-radius: 0px 11px 0px 0px;
}

.santa .hat .hat-end:after {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  content: "";
  display: inline-block;
  top: 30px;
  position: absolute;
  left: 0px;
  z-index: 11;
}

.circles {
  background: #a31f2b;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 9;
  position: absolute;
  right: -6px;
  top: 12px;
}

.circles:after {
  background: white;
  width: 40px;
  height: 40px;
  right: 0;
  top: 50px;
  content: "";
  border-radius: 50%;
}

.dirty-overflow {
  overflow: hidden;
  width: 120px;
  height: 76px;
  border-radius: 0px 0px 105px 105px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.grinch {
  background: url(assets/grinch.svg);
  width: 200px;
  height: 200px;
  position: fixed;
  top: 40%;
  left: -2em;
  display: flex;
  z-index: 8;
  flex-direction: column;
  filter: drop-shadow(0px 0px 10px rgba(32, 97, 68, 0.8));
}

.grinch span:nth-child(2) {
  position: relative;
  top: 38px;
  left: 27px;
  font-size: 1.1em;
}

.grinch span:nth-child(3) {
  position: relative;
  top: 38px;
  left: 27px;
  font-size: 0.8em;
}

.find-me-grinch {
  width: 256px;
  height: 256px;
  background-image: url(./assets/grinchFull.webp);
  background-size: contain;
  position: absolute;
  top: 0;
  left: -256px;
  transition: 1s;
  z-index: 2;
  transform: rotate(90deg);
}

.grinch:hover .find-me-grinch {
  left: 10px;
}

.send-wish {
  margin: 15px auto;
  display: block;
  background-color: #0f8a5f;
  color: #fff;
  padding: 14px 18px !important;
  text-align: center;
  max-width: 50%;
  outline: none;
  border: 1px solid #fff;
  text-transform: uppercase;
  font-size: 12px;
  transition: 0.3s all;
  box-shadow: 0px 0px 0px 5px #0f8a5f;
  text-decoration: none;
}

.send-wish:hover {
  background-color: #eb5f5f;
  box-shadow: 0px 0px 0px 5px #eb5f5f;
}

h2 {
  margin: 0.8em auto;
  color: #0f8a5f;
  text-transform: uppercase;
  text-align: left;
  font-size: 1.4em;
  letter-spacing: 1px;
}

.warning {
  color: #eb5f5f;
  font-size: 1.1em;
  margin: auto;
  width: 80%;
}

input,
textarea,
select {
  margin: 15px auto;
  color: #222;
  padding: 15px 5px !important;
  text-align: left;
  width: 100%;
  max-width: calc(100% - 60px);
  outline: none;
  border: 0;
  font-size: 1.1em;
  background-color: transparent;
  border-bottom: 1px solid #0f8a5f;
}

@media screen and (max-width: 768px) {
  .grinch {
    display: none;
  }
}

// gift modal

.gift-img {
  background-image: url(assets/gift.svg);
  width: 12em;
  height: 12em;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.gift-text {
  color: #0f8a5f;
  text-align: left;
  font-size: 1.25em;
  margin: 0.4em 0.1em;
}

video {
  margin: 1em 0.1em;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 5px rgba(32, 97, 68, 0.2));
}
</style>
